.small-size-warning-popup-content {
  @media (max-width: 600px) {
    width: 90%;
  }

  .small-size-warning {
    text-align: center;
    margin: 30px;

    @media (max-width: 600px) {
      h2 {
        font-size: 32px;
      }

      h4 {
        font-size: 16px;
      }
    }
  }
}

.small-size-warning-denial {
  p {
    max-width: 350px;
    text-align: center;
  }
}

.small-size-warning-choice {
  .small-size-warning-choice-subtitle {
    margin-bottom: 15px;
  }

  .small-size-warning-buttons {
    display: flex;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .small-size-warning-button {
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      margin-right: 20px;
      padding: 10px 10px 20px 10px;

      @media (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      img {
        margin-bottom: 1rem;

        @media (max-width: 600px) {
          width: 80px;
          margin-bottom: 0;
          font-size: 14px;
        }
      }

      p {
        max-width: 60%;
        text-align: center;
        margin: auto;

        @media (max-width: 600px) {
          max-width: 80%;
          font-size: 16px;
          line-height: 22px;
        }
      }

      strong {
        opacity: 0.75;
        font-size: 16px;
        letter-spacing: 0.36px;
        line-height: 13px;
        letter-spacing: 0.075rem;
        text-transform: uppercase;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      &:hover {
        background: #ffff01;
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }
    }
  }
}
