.rescue-placement-container {
  padding: 0 20px;
  margin: 20px auto;
  max-width: 550px;

  p {
    text-align: left;
  }

  h2 {
    font-size: 38px;
  }

  .pet-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pet-arrow {
      display: none;
    }

    &.clickable {
      cursor: pointer;

      .pet-arrow {
        display: block;
      }
    }

    img {
      border-radius: 50%;
    }

    .pet-title {
      flex: 1;
      margin-left: 10px;
    }
  }

  .foster-transfer-form {
    display: flex;
    flex-direction: column;
  }
}
