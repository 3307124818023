@import "./shared/variables";

$gutter-width: 40px;
$border-color: rgba(
  $color: #000000,
  $alpha: 0.15
);

/*
 * Subscription Styles
 */
.subscription-container {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 800px;

  @include small-screen() {
    padding: 0 15px 20px;
  }

  h2.subscription-header {
    font-size: 38px;
    margin: 0;
  }

  .subscription-subheader {
    margin-bottom: 30px;

    .star {
      color: $savings-green;
      font-size: 0.8em;
    }

    h4 {
      font-weight: 600;
      font-size: 1.375rem;
    }
  }

  .properties {
    margin: 1rem 0 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 0;
      justify-content: space-evenly;
      align-items: center;
      text-align: left;
      list-style-image: url(/check_mark.svg);
      padding: 0;

      li {
        flex-shrink: 1;
        flex-basis: 40%;
        box-sizing: border-box;
        margin: 0.4rem 0.5rem;
        letter-spacing: 0.0225rem;

        @include small-screen() {
          flex-basis: 75%;
        }
      }
    }
  }

  .subscription-group-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;

    .subscription-tile {
      min-height: 250px;
      width: 220px;

      padding: 40px 5px 25px;
      margin: 0 25px;

      border: 1px solid $border-color;
      border-radius: 4px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @include small-screen() {
        margin: 5px 0;
      }

      & > * {
        flex-grow: 0;
      }

      .dog-sizer {
        height: 77px;
        width: 77px;
      }

      .content {
        flex-grow: 1;
      }

      .most-popular-callout {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
      }

      .main-content h3 {
        margin: 10px 0 0;
        font-size: 2.125rem;
      }

      .savings {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 12.5px;
        background-color: $savings-green;
        height: 25px;

        color: #fff;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.015rem;
        text-transform: uppercase;

        margin: 1rem 0;
        padding: 1px 20px 0;
      }

      .subscription-price,
      .price-disclaimer {
        color: #727272;
        letter-spacing: 0.015rem;
      }

      .subscription-price {
        font-size: 1.375rem;
      }

      .price-disclaimer {
        font-size: 0.875rem;
      }
    }

    @include small-screen() {
      flex-direction: column;
    }
  }

  .subscription-free > a {
    color: $blue;
    border-bottom: 1px solid $blue;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $gutter-width 0;
  }

  /*****
   * Styling for the tiles select and hover states
   */

  .subscription-group-container {
    counter-reset: plan-count;
  }

  .subscription-tile {
    counter-increment: plan-count;

    box-shadow: none;
    position: relative;

    transition: box-shadow 0.25s;

    .action {
      padding-top: 1rem;
    }

    &::before {
      content: "";
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 25px;

      transition: height, background-color;
      transition-duration: 0.25s;
    }

    &:nth-child(1)::before {
      background-image: linear-gradient(to bottom right, $light-gray, #e6e6e6);
    }

    &:nth-child(2)::before {
      background-image: linear-gradient(to bottom right, #aaaaaa, #808080);
    }

    &:nth-child(3)::before {
      background-image: linear-gradient(to bottom right, #404040, #000000);
    }

    &.most-popular {
      &::before {
        background-image: none;
        background-color: $yellow;
        height: 35px;
      }
    }
  }
}
