.accessories-page {
  .accessories-module {
    margin-top: 1rem;
  }
  .accessories-module-header {
    text-align: center;
    margin-bottom: 2rem;

    h2 {
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 800;
      letter-spacing: -0.075rem;
      font-size: 38px;
      line-height: 1;
    }

    p {
      text-align: center;
      margin: 0;
      font-size: 1.375rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .accessories-module-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .accessories-module-item-container {
      margin: 25px;
      height: 320px;
      width: calc(50% - 50px);

      @media (max-width: 750px) {
        width: calc(100% - 50px);
      }

      .accessories-module-item {
        overflow: hidden;

        background-color: $light-gray;
        position: relative;
        width: 100%;
        height: 100%;

        transition: 0.15s ease-in-out transform,
          0.25s ease-in-out background-color;

        &:hover {
          transform: scale(1.02);
          background: $yellow;
        }
      }

      .accessories-module-item-image {
        position: absolute;
        width: 100%;
        max-height: 100%;

        top: -25px; // Vertically offset the centering by 25px

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        img {
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .accessories-module-item-description {
        position: absolute;
        bottom: 25px;
        left: 35px;

        h4 {
          margin-bottom: 0;
          font-weight: 600;
          line-height: 1.1;
        }

        .accessories-module-item-description-price {
          color: rgba(0, 0, 0, 0.55);
          margin: 0;
          margin-top: 6px;
          display: block;
          font-weight: 300;
        }
      }
    }
  }
}
