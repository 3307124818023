@import "./variables";

.button {
  border-radius: 2px;
  border: 1px solid transparent;
  color: #737373;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 0.5rem;
  min-width: 9.5rem;
  padding: 0.45rem 1rem 0.25rem;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
}

.flow-button {
  border-radius: 4px;
  border: 1px solid $black-70;
  box-sizing: border-box;
  box-shadow: none;
  color: $black-70;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  height: 2.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 0.5rem;
  min-width: 9.5rem;
  max-width: 400px;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;

  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    border-width 0.15s, box-shadow 0.15s, transform 0.15s;

  transition-timing-function: ease-in-out;

  &:hover:not(.flow-button--disabled) {
    border-color: #000;
    border-width: 2px;
    color: #000;
  }
}

a.flow-button {
  display: inline-block;
}

button.flow-button {
  padding: 0.25rem 1rem 0.2rem;
  font-family: "din-2014", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
}

.flow-button--yellow {
  @extend .flow-button;

  color: #000;
  background-color: $yellow;
  border: none;
  box-shadow: 0 0 2px $black-10;

  &:hover:not(.flow-button--disabled) {
    background-color: $yellow;
    box-shadow: 0 0 4px $black-20;
    color: #000;
    border: none;
  }

  @media (max-width: 600px) {
    max-width: unset;
  }
}

.flow-button--black {
  @extend .flow-button;

  color: #ffffff;
  background-color: #000000;
  border: none;

  &:hover:not(.flow-button--disabled) {
    background-color: #090909;
    color: #fff;
    border-width: 1px;
  }
}

.flow-button--continue,
a.flow-button--continue {
  @extend .flow-button--black;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &::after {
    content: "→";
    font-size: 1rem;
    margin-top: -2px;
    opacity: 0;
    overflow: hidden;
    text-align: right;
    width: 0;
    transition: width 0.15s, opacity 0.25s;
    transition-timing-function: ease-in-out;
  }

  &:not(.flow-button--disabled):hover::after {
    opacity: 1;
    width: 1.5rem;
  }
}

.flow-button--disabled {
  border: 1px solid #000000;
  color: #000000;
  background: #ffffff;
  cursor: default;
  opacity: 0.15;
}

/*
 * Legacy Button Styles
 */

.button--primary {
  color: #ffffff;
  background-color: #000000;
  border-color: black;
}

.button--epic {
  color: #000000;
  border-color: #ffff01;
  background-color: #ffff01;
  text-transform: uppercase;
}

.button--plain {
  border: none;
  opacity: 0.55;
  text-transform: none;
  font-weight: normal;
}

.button--halfwidth {
  width: calc(50% - 11px);
  margin-left: 5px;
  margin-right: 5px;
}

.button--outline {
  font-weight: normal;
  border: 1px solid black;
  color: black;
}

.button--disabled {
  border: 1px solid #000000;
  color: #000000;
  background: #ffffff;
  cursor: default;
  opacity: 0.15;
}

.button--gray {
  color: black;
  background-color: rgba(194, 194, 194, 0.4);
  border: 1px solid #c2c2c2;
}
