.referrals-tiers {
  .referral-reward-list {
    .referral-reward-separator {
      margin-left: 20px;
      margin-top: 2px;
      height: 28px;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.55) 60%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position-x: left;
      background-size: 2px 10px;
      background-repeat: repeat-y;
    }

    .referral-reward {
      height: 135px - 20px * 2;

      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-position-x: right;
      background-position-y: top;
      background-size: auto 135px;
      background-repeat: no-repeat;
      padding: 20px;

      @media (max-width: 600px) {
        font-size: 0.75em;
      }

      h5 {
        margin: 0;
        text-transform: uppercase;
      }

      &.free-band {
        background-image: url("/referrals/free_band_bg.png");
      }

      &.one-year {
        background-image: url("/referrals/1yr_sub_bg.png");
      }

      &.two-years {
        background-image: url("/referrals/2yr_sub_bg.png");
      }

      &.three-years {
        background-image: url("/referrals/3yr_sub_bg.png");
      }
    }
  }
}

@keyframes referral-code-toast-animation {
  0% {
    opacity: 0;
    top: -5px;
  }

  30%,
  60% {
    opacity: 1;
    top: -10px;
  }

  100% {
    opacity: 0;
    top: -20px;
  }
}

.referral-code-toast {
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;

  .referral-code-toast-content {
    padding: 5px 15px;
    background: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    color: white;
    cursor: pointer;
    border-radius: 2px;
    font-size: 18px;
    position: absolute;
    opacity: 0;
    display: none;

    &.shown {
      display: block;
      animation-timing-function: ease-in-out;
      animation-name: referral-code-toast-animation;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
}

.referral-code-container {
  .referral-code-inner {
    position: relative;

    .referral-code-code {
      border: 1px solid rgba(0, 0, 0, 0.09);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8.91px;
      cursor: pointer;
      font-size: 44px;
      color: #000000;
      line-height: 55px;
      padding: 9px 0 3px 20px;
      flex: 1;
    }

    .referral-share-button {
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      background: #000000;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 6.93px;
      text-align: center;
      padding: 12px 15px;
      cursor: pointer;
      margin: 10px;
      text-transform: uppercase;
    }
  }
}

.referrals-page {
  h4 {
    text-align: center;
  }

  .referrals-tiers-inner {
    margin: 20px 0;
  }

  .referrals-main,
  .referrals-tiers {
    .single-column-container {
      margin: 0 15px;
    }
  }

  .referrals-header {
    background-color: #ffff01;
    text-align: center;
    padding: 30px 0;

    .referrals-header-inner > * {
      margin-top: 0;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .referrals-main {
    background: #ffffff;
    padding-bottom: 15px;

    .referrals-main-inner {
      margin: 20px 0;
    }

    .referrals-main-information {
      text-align: center;
      margin: 15px auto 0 auto;
      max-width: 300px;
    }
  }
}
