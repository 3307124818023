@import "./shared/variables";

$guide-width: 460px;

@keyframes slide-in-with-fade {
  0% {
    opacity: 0;
    transform: translateX($guide-width);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.product-sizing-guide {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  min-height: 100vh;

  z-index: 1000;

  @media (max-width: 720px) {
    height: 100%;
  }

  .click-capture {
    flex-grow: 1;
  }

  .sizing-guide-contents {
    animation: slide-in-with-fade 0.25s ease-in-out 1;

    &.fade-out {
      animation: fade-out 0.25s ease-in-out 1 forwards;
    }

    background-color: white;
    box-shadow: 0 0 10px $black-10;
    padding: 0;
    text-align: left;

    display: flex;
    flex-direction: column;
    width: calc(100vw - 20px);
    max-width: 460px;

    h4,
    h5 {
      text-transform: uppercase;
    }

    h4 {
      color: $black-70;
      font-size: 1.25rem;
      letter-spacing: 0.08rem;
    }

    h5 {
      color: $black-50;
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.04375rem;
    }
  }

  .guide-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px 10px;

    .close-button {
      border: none;
      padding: 5px;

      height: 24px;
      width: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .guide-sizes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    padding: 0 45px 30px;
    border-bottom: 1px solid $black-10;
  }

  .sizing-block {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .sizing-details {
      display: flex;
      flex-direction: column;
    }

    .sizing-stats,
    .sizing-stats > div {
      display: flex;
      flex-direction: row;

      @media (max-width: 720px) {
        flex-direction: column;
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .sizing-stats {
      justify-content: space-between;
    }

    .sizing-stats .length {
      flex-basis: 67%;
    }
  }

  .sizing-block {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid $black-10;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out;

    @media (max-width: 720px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .sizing-details {
      h5,
      p,
      .sizing-stats span {
        font-size: 1rem;
        letter-spacing: 0.0225rem;
        line-height: 1.4;
        text-align: left;
        margin: 0;
      }

      p {
        font-weight: 300;
        margin-bottom: 0.4rem;
      }

      span {
        font-weight: 400;
      }

      h5 {
        margin-right: 5px;
      }
    }
  }

  .sizing-block:hover:not(.unavailable) {
    border-color: $blue;
    cursor: pointer;
  }

  .dog-icon {
    background-color: $yellow;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 5px 2px;
    margin-right: 14px;
    width: 50px;

    & > img {
      width: 40px;
    }
  }

  .unavailable {
    .dog-icon {
      background-color: $light-gray;

      img {
        opacity: 0.4;
      }
    }

    .length span {
      color: $red;
    }
  }

  .still-unsure {
    padding: 40px 50px;
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 0.016rem;
    font-weight: 600;

    a {
      font-weight: 400;
      color: $blue;
    }
  }
}
