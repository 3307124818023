@import "./shared/variables";
@import "./shared/mixins";

@mixin page-cell() {
  border-radius: 8px;
  border: 1px solid $black-10;
  margin-bottom: 30px;
}

.cart-container {
  padding: 3rem 0;

  @media (max-width: 800px) {
    &.split-container {
      flex-direction: column;
    }

    .cart-actions {
      margin-top: 2rem;
    }
  }

  & > div {
    align-items: stretch;
  }

  .celled-section {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 25px;
    padding-bottom: 20px;

    h3 {
      @include muted-gray();
      color: $black-70;
      font-size: 1.25rem;
    }
  }

  // The other features container
  .extra-info {
    @include page-cell();

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    padding: 1.25rem 0.5rem;

    & > div {
      display: flex;
      flex-direction: row;
      flex-basis: 25%;
      justify-content: center;
      align-items: center;

      p {
        font-size: 1rem;
        text-align: left;
        line-height: 20px;
        margin: 0;
        color: rgba(0, 0, 0, 0.8);
      }

      img {
        margin-top: -8px;
        margin-right: 0.75rem;
        height: 40px;
        width: 40px;
        flex-shrink: 0;
      }
    }
  }

  .free-trial-items {
    .line-item-list {
      margin-bottom: 25px;
    }

    .line-item {
      margin: 10px 0;
    }
  }

  .line-item-list {
    .product-group:last-child {
      margin-bottom: 1.25rem;
    }
  }

  .cart-summary {
    @include page-cell();
    overflow: hidden;
    padding: 0;

    & > div {
      padding: 1.75rem;
    }

    h4 {
      font-size: 2.375rem;
      font-weight: 600;
      letter-spacing: -0.02rem;
      margin: 0.75rem 0.8125rem;
    }

    .price-value {
      font-weight: 200;
    }

    .price-data {
      background-color: $light-gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .row {
        justify-content: center;
      }
    }

    .gift-card-applied {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $black-10;

      > div {
        display: flex;
        align-items: center;
      }

      strong {
        text-transform: uppercase;
      }

      img {
        position: relative;
        margin-right: 10px;
        top: -2px;
      }

      > * {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .cart-summary__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      & > * {
        flex-grow: 1;
        margin: 0 0.375rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch;

        & > * {
          margin: 0.375rem 0;
          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .cart-callout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 15px;
    min-height: 40px;

    &.gps-upsell {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .upsell-muted {
        @include muted-gray();
        margin-top: 3px;
        text-transform: none;

        a {
          color: $blue;
        }
      }

      .plus {
        width: 1rem;
        display: inline-block;
        text-align: left;
      }
    }

    .callout-image {
      width: 100px;
      height: auto;
      margin: 0;
      margin-right: 35px;
      opacity: 0.55;
      transition: opacity 0.15s ease-in-out;

      img {
        width: 100%;
      }
    }

    &:hover .callout-image {
      opacity: 1;
    }

    .cart-callouts-button {
      color: $blue;
      font-weight: 500;
      font-size: 1.25rem;
    }

    .referee-reward {
      @include page-cell();
      align-items: center;
      background-image: url("/bag_referee_callout.png");
      background-position: right;
      background-repeat: no-repeat;
      background-size: 180px;
      color: black;
      display: flex;
      flex: 1;
      font-size: 18px;
      height: 110px;
      justify-content: normal;
      margin: 0.5rem 10px 1rem 0;
      padding: 5px 20px;
      transition: all ease-in-out 0.1s;

      p {
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        margin: 0 auto;
      }

      h5 {
        margin: 0;
        font-size: 30px;
      }

      &:hover {
        background-size: 190px;
        border: 1px solid rgba(2, 126, 255, 0.5);
      }
    }
  }
}

.promo-code-container {
  margin-bottom: 30px;

  .promo-code-form {
    @include page-cell();
    overflow: hidden;
    height: 46px;

    $coupon-error-color: #ff4242;

    display: flex;
    padding: 6px;

    input[type="text"] {
      flex: 1;
      border: none;
      margin: 0;
      padding: 10px 15px;
    }

    .promo-action {
      height: 100%;
      border-radius: 8px;
      border: 1px solid $black-10;
      flex-grow: 0;
      margin: 0;
      min-width: unset;
      min-height: unset;
      padding: 0.45rem 1.75rem 0.25rem;
    }

    .flow-button--gray {
      background-color: $light-gray;
      &:hover {
        border-color: $black-50;
      }
    }

    .flow-button--yellow {
      &::before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 16px;
        background: url("/gift_box_gray.svg");
        margin-right: 4px;
        margin-top: -3px;
      }
    }

    &.promo-code-form--error {
      input[type="text"]::placeholder {
        color: $coupon-error-color;
      }

      .promo-action {
        border: 1px solid $coupon-error-color;
      }
    }
  }

  .promo-code-gift-card-toggle {
    margin: 1.5rem 0.5rem;

    font-size: 1.125rem;
    letter-spacing: 0.016rem;

    span {
      margin-left: 2px;
    }
  }
}
