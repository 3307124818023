@import "./shared/mixins";

.checkout-container {
  max-width: 600px;
  margin: 0 auto;

  .loading {
    padding-bottom: 50px;
  }

  .breadcrumbs {
    text-align: center;

    .checkout-breadcrumb {
      margin: 0;
      text-transform: none;

      &.disabled {
        cursor: default;
      }

      &.text--bolder {
        color: black;
      }

      min-width: unset;
    }
  }

  .login-logout {
    color: rgba(0, 0, 0, 0.55);
    font-size: 1.25rem;
  }

  form {
    padding: 1rem 0 2rem;

    .form-row {
      margin: auto -7px;
      flex-wrap: wrap;

      input:not([type="submit"]),
      select,
      .react-select__control,
      label {
        margin-left: 7px;
        margin-right: 7px;
      }

      .zip-code {
        max-width: 130px;
      }
    }

    input:not([type="submit"]),
    select,
    .react-select-container,
    label {
      font-size: 1.25rem;
    }

    input:not([type="submit"]),
    select,
    label,
    .react-select-container {
      padding: 5px 0;
      margin: 8px 0;
    }

    input:not([type="submit"]) {
      padding-bottom: 2px;
    }

    select {
      padding-left: 10px;
      padding-right: 5px;
    }

    input[type="checkbox"] {
      transform: scale(1.9);
    }

    .recurly-hosted-field-card {
      padding: 0;
      height: 2.6em;
    }

    @media (max-width: 600px) {
      .form-row .zip-code {
        max-width: unset;
      }
    }
  }

  .peek {
    line-height: 1.5;

    &.billing {
      .billing-address.is-same {
        @include muted-gray();
      }
    }
  }

  .action-container {
    margin: 1rem 0;
  }

  .billing-section [data-recurly="card"] {
    margin-left: -7px;
  }

  .shipping-option-list {
    margin: 1rem 0;

    .shipping-option {
      border-bottom: 1px solid $black-10;

      label.header-4,
      h4 {
        font-size: 1.25rem;
        margin-top: 0;
        margin-bottom: 0;
      }

      .checkbox-icon {
        margin-right: 25px;
        margin-top: -3px;
      }
    }

    .flow-button--yellow {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .purchase-container {
    .product-group {
      border-top: 1px dashed $black-10;

      &:first-child {
        border-top: none;
      }
    }

    h4 {
      font-size: 1.5rem;
    }

    .cart-subtotal,
    .cart-summary .row {
      padding: 0 5px;
    }

    .cart-summary .row:not(.cart-total) {
      &:first-child {
        padding-bottom: 0;
      }

      h4 {
        font-weight: 300;
      }
    }

    .line-item {
      div:first-child {
        padding-left: 0;
        margin-left: 0;
      }

      div:last-child {
        padding-right: 0;
        margin-right: 0;
      }
    }

    .cart-total,
    .cart-subtotal {
      border-top: 1px solid $black-10;
      border-bottom: 1px solid $black-10;

      &.cart-subtotal {
        border-top-style: dashed;
        border-bottom-style: dashed;
      }
    }

    .cart-summary-disclaimer {
      @include muted-gray();
      font-size: 0.75rem;
    }
  }
}
