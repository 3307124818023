@import "./shared/mixins";

.gift-card-page {
  max-width: 920px;
  margin: 0 auto;

  .gift-card-package-picker {
    flex-direction: column;

    .gift-product-list-container {
      flex-direction: row;
    }

    .gift-product {
      flex-grow: 1;
      margin: 0;
      margin-right: 20px;
      flex-basis: 100px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .gift-card-header {
    background-color: #ffff01;
    text-align: center;
    padding: 30px 0;

    .gift-card-header-breadcrumb {
      text-align: left;

      width: calc(100% - 20px);
      max-width: 1100px;
    }

    .gift-card-header-main {
      display: flex;
      flex-direction: row;

      h2 {
        font-size: 38px;
        font-weight: 600;
        margin: 16px 0;

        @media (max-width: 600px) {
          margin: 15px 0;
        }
      }

      img {
        margin-right: 1rem;
      }
    }
  }

  h2 {
    font-size: 38px;
    font-weight: 300;
    margin: 0 0 20px 0;

    @media (max-width: 600px) {
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: 300;
  }

  .section-title {
    margin: 26px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .subheader-with-message {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    span {
      font-size: 1.125rem;
      margin-left: 20px;
      opacity: 0.55;
    }
  }

  .gift-card-image-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  // Gift card glint effect
  .gift-card-image-wrapper {
    position: relative;
    overflow: hidden;

    img {
      display: block;
    }

    @keyframes gift-card-glint {
      0% {
        transform: skewX(-45deg) translateX(0);
      }
      5% {
        transform: skewX(-45deg) translateX(570px);
      }
      100% {
        transform: skewX(-45deg) translateX(570px);
      }
    }

    &::before {
      content: "";
      background-color: rgba(255, 255, 255, 0.5);
      height: 100%;
      width: 3em;
      display: block;
      position: absolute;
      top: 0;
      left: -150px;
      transform: skewX(-45deg) translateX(0);
      transition: none;
      animation-duration: 10s;
      animation-timing-function: ease-in-out;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      animation-name: gift-card-glint;
    }
  }

  .disabled-section .gift-card-image-wrapper::before {
    animation: none;
  }

  .split-container {
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .gift-card-form-container {
    .basic-form div.text--danger {
      margin-top: -1rem;
      margin-bottom: 1rem;
    }

    input {
      font-size: 1.8rem;
      padding: 2px 0;
      margin: 1rem 0 1.75rem;
    }

    @media (max-width: 600px) {
      & > * {
        align-items: flex-start;
      }
    }
  }

  .gift-card-actions {
    padding-bottom: 60px;

    .single-column {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      width: calc(100% - 20px);
    }

    .flow-button {
      flex: 1;
      margin: 0 20px;
    }
  }

  .gift-card-page-section {
    padding: 55px 0;
    border-bottom: 1px solid #d8d8d8;

    transition: opacity 0.5s;

    @include pretty-p();

    &.disabled-section {
      opacity: 0.15;
    }

    &:first-child {
      padding-top: 0;
      margin-top: 50px;
    }

    &:last-child {
      border-bottom: none;
    }

    .split-container > * {
      align-items: flex-start;
    }
  }

  .gift-card-picker-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: 600px) {
      p {
        max-width: 400px;
      }
    }
  }

  .gift-product-list-container {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    align-items: stretch;

    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .gift-product {
    display: flex;
    flex-direction: column;

    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 2px;

    color: rgba(0, 0, 0, 0.8);
    font-size: 1.5rem;

    margin: 10px 0;
    padding: 17px 20px 15px 20px;
    min-height: 52px;

    transition: border-color 0.15s, box-shadow 0.5s, color 0.5s,
      padding-top 0.1s, border-top-width 0.1s;

    cursor: pointer;

    &.disabled {
      cursor: default;
    }

    .gift-product-popular {
      font-size: 12px;
      color: #027eff;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .gift-product-main {
      display: flex;
      flex: 1;
      align-items: center;
      font-weight: 600;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #000000;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
      border-color: rgba(0, 0, 0, 0.15);
    }

    &.selected {
      border-color: #027eff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      color: #000000;
      border-top-width: 3px;
      padding-top: 15px;

      &:hover {
        border-color: #027eff;
      }
    }

    .gift-sub-products {
      flex: 1;
      font-weight: 300;

      li {
        display: flex;
        align-items: baseline;
      }

      .sub-product-deemphasized {
        opacity: 0.7;

        .sub-product-name {
          font-weight: 400;
        }
      }

      .sub-product-name {
        font-weight: 600;
        flex: 1;
      }

      .sub-product-price {
        margin-left: 10px;
      }

      .sub-product-savings {
        font-size: 16px;
        color: #2cb355;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
  }

  .delivery-date-input-container {
    > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .delivery-date-date {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      padding-bottom: 0.5rem;

      select {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .checkbox-custom {
    font-size: 20px;
    display: flex;
  }

  textarea {
    resize: none;
    min-height: 150px;
    box-sizing: border-box;
    border-radius: 2px;
  }

  // TODO: This should be a common style in App.scss
  select:disabled {
    opacity: 0.5;
  }
}
