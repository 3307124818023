@import "./variables";

.accordion-section {
  border-bottom: 1px solid $black-10;
  padding: 1rem 0;

  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:last-child {
    border-bottom: none;
  }

  .accordion-header {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 38px;
      margin: 0;
    }

    .expander-button {
      margin-left: 20px;
    }
  }

  &.disabled .accordion-header {
    cursor: default;
    opacity: 0.25;
  }

  &.expanded {
    .accordion-contents > .accordion-peek {
      display: none;
    }

    .expander-button img {
      transform: rotate(0.5turn);
    }
  }

  &.collapsed {
    .accordion-contents > * {
      display: none;
    }

    .accordion-contents > .accordion-peek {
      display: flex;
    }
  }
}
