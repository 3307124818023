/*
 * Product Details
 */

@keyframes buy-action {
  0% {
  }
  2% {
    background-color: $yellow;
    border-color: $yellow;
    transform: translateY(2px);
  }
  80% {
    background-color: $yellow;
    border-color: $yellow;
    transform: translateY(2px);
  }
  100% {
  }
}

@keyframes buy-contents {
  0% {
  }
  5% {
    transform: translateY(37px);
  }
  80% {
    transform: translateY(37px);
  }
  100% {
  }
}

.split-container.product-container {
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 720px) {
    flex-direction: row;
    align-items: flex-start;
  }

  h2 {
    font-size: 2.375rem;
    margin-top: 1.75rem;
    margin-bottom: 0;
  }

  @include pretty-p();
}

.product-name-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .featured-image-container {
    height: 350px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    @media (min-width: 720px) {
      flex-grow: 1;
      height: unset;
      max-height: 400px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
    }
  }

  .more-photos {
    width: 100%;
    margin-top: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    .gallery-photo {
      cursor: pointer;
      margin: 7px;
      overflow: hidden;
      position: relative;

      border-radius: 4px;
      border-color: rgba($color: #000000, $alpha: 0.1);
      border-style: solid;
      border-width: 1px;

      height: 50px;
      width: 50px;

      @media (min-width: 720px) {
        height: 60px;
        width: 60px;
        margin: 15px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .detail-price {
    padding-left: 25px;
    padding-bottom: 0;
    font-weight: 300;
  }
}

.product-details {
  justify-content: flex-start;

  p {
    text-align: center;
  }

  @media (min-width: 720px) {
    padding-top: 12px;
  }
}

.variant {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin: 15px 0;
  width: calc(100% - 30px);

  @media (min-width: 720px) {
    padding-bottom: 0;
    width: calc(100% - 20px);
  }
}

.variant__title {
  @include muted-gray();
  margin-bottom: 0.5rem;
}

.variant__options {
  margin-bottom: 0.5rem;
}

.variant__size-description {
  opacity: 0.55;
  text-align: center;
}

@media (max-width: 720px) {
  .variant__size-description {
    margin-top: 5px;
  }
}

.variant__size-chart {
  text-align: center;
  button {
    color: $blue;
    opacity: 0.65;
    font-size: 1rem;
    letter-spacing: 0.0225rem;
    text-transform: none;
    font-weight: 300;
  }
}

.product-action {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: calc(100% - 30px);

  .flow-button.buy-button {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .add-to-bag-added {
      display: none;
      font-weight: 600;
    }

    &.is-buying {
      animation: buy-action 1.5s ease-in-out 1;

      .add-to-bag-added {
        display: block;
      }

      .add-to-bag-contents {
        animation: buy-contents 1.5s ease-in-out 1;

        > div {
          height: 30px;
          padding: 3px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .action-button {
    transition: width, margin, min-width, flex-grow, opacity 0.15s 0.2s;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    .flow-button {
      width: 100%;
    }

    &.hide-flow {
      flex-grow: 0;
      opacity: 0;

      margin-left: 0;
      margin-right: 0;
      width: 0;

      .flow-button {
        min-width: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
      }
    }
  }
}
