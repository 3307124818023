@mixin muted-gray() {
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.55);
  font-weight: 400;
}

@mixin small-screen() {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin big-screen() {
  @media (min-width: 720.1px) {
    @content;
  }
}

// Mix this in to a <del> element to get a pronounced strikethrough style.
@mixin strikethrough-del($color) {
  text-decoration: none;
  position: relative;

  &::after {
    content: "";
    border-bottom: 2px solid $color;
    position: absolute;
    left: -2px;
    right: -2px;
    margin-top: calc(0.125em / 2 * -1);
    top: 50%;
  }
}

@mixin pretty-p {
  p {
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
    line-height: 2rem;
    color: #222;
  }
}
