@import "./styles/shared/buttons";
@import "./styles/shared/appbar";
@import "./styles/shared/line-items";
@import "./styles/shared/accordion";

@import "./styles/subscription";
@import "./styles/sizing-guide";
@import "./styles/cart";
@import "./styles/checkout";
@import "./styles/thank-you";
@import "./styles/accessories";
@import "./styles/shared/mixins";
@import "./styles/gift-card";
@import "./styles/referrals";
@import "./styles/rescue";
@import "./styles/product-details";
@import "./styles/small-size-warning";
@import "./styles/rescue-placement";

@font-face {
  font-family: "din-2014";
  src: url("./assets/fonts/din-2014-narrow/regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "din-2014";
  src: url("./assets/fonts/din-2014-narrow/light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "din-2014";
  src: url("./assets/fonts/din-2014-narrow/demi-bold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "din-2014";
  src: url("./assets/fonts/din-2014-narrow/extra-bold.woff") format("woff");
  font-weight: 800;
}

body,
input,
.button,
textarea,
select {
  font-family: "din-2014", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 300;
  font-size: 1.25rem; /* 20px is standard for Fi sites */
}

input[type="text"],
input[type="password"] {
  &.error {
    $error-color: #ff4242;
    &::placeholder {
      color: $error-color;
    }
    border-bottom: 1px solid $error-color;
  }
}

body {
  max-width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

strong {
  font-weight: 600;
}

.green-star {
  display: inline-block;
  margin: 1px 5px 1px 1px;
  width: 12px;
  height: 11px;
  background: url("/green_star.svg");
}

.grey-bg {
  background-color: $light-gray;
}

/*
 * Typography
 */

h1 {
  text-transform: uppercase;
  font-size: 43px;
  margin: 20px 0px;
  font-weight: bold;
}
@media (min-width: 600px) {
  h1 {
    font-size: 87px;
  }
}

h2 {
  font-size: 43px;
  font-weight: 600;
  margin-bottom: 10px;
  @media (min-width: 600px) {
    font-size: 58px;
  }
}

h3 {
  font-size: 29px;
  font-weight: 600;
}

h4,
.header-4 {
  font-size: 22px;
  font-weight: 400;
}

@media (min-width: 600px) {
  h4,
  .header-4 {
    font-size: 29px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5em 0;
}

h5 {
  font-size: 1em;
  font-weight: 600;
}

p {
  text-align: center;
}
@media (min-width: 600px) {
  p {
    text-align: left;
  }
}

a,
.link {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.link--underlined {
  text-decoration: underline;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--highlight {
  color: $blue;
  font-weight: 200;
}

h4 .text--highlight {
  font-weight: 400;
}

.text--lowlight {
  color: #8c8c8c;
}

.text--danger {
  color: $red;
}

.text--muted {
  font-size: 0.75em;
  font-weight: 200;
}

.text--bolder {
  font-weight: 600;
}

.link--subtle {
  font-weight: 300;
  opacity: 0.6;
  transition: opacity 0.3s;
}

.link--subtle:hover {
  opacity: 1;
}

ul.list--unstyled {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/*
 * Inputs
 */

input,
select,
button,
textarea {
  background: none;
  width: auto;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

input[type="email"],
input[type="password"],
input[type="tel"],
input[type="text"] {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  margin: 1em 0;
  padding: 0.75em 0;
}

textarea {
  border: 1px solid #d8d8d8;
  padding: 10px;
}

.form-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: auto -5px;
}
@media (min-width: 600px) {
  .form-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.form-row > input[type="email"],
.form-row > input[type="password"],
.form-row > input[type="tel"],
.form-row > input[type="text"],
.form-row > select,
.form-row > .react-select-container {
  flex: 1 1 0;
  margin: 1em 5px;
}

.react-select-container {
  margin: 0;
  position: relative;
  top: -5px;

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px $blue;
    border-color: $blue;
  }

  .react-select__option--is-selected {
    background-color: $blue;
  }

  .react-select__input {
    height: 30px;

    input {
      margin: 1px 0 1px -1px !important;
    }
  }

  .react-select__indicator-separator {
    background-color: rgba(0, 0, 0, 0);
  }

  .react-select__value-container *:last-child {
    padding: 0;
  }
}

.form-row.form-row--checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  margin: 1em 0px;
}

.recurly-hosted-field-card {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  padding: 0.75em 0;
  height: 3.5em;
}

label {
  font-size: 12px;
  padding-left: 0.5em;
}

select {
  color: #000000;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 0.25rem 2em 0.25rem 0.75em;
  width: auto;
  appearance: none; /* needed */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23d8d8d8'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 1em;
  background-position: calc(100% - 0.5em) calc(50% + 0.25rem);
  background-repeat: no-repeat;
  background-color: transparent;
}

// Custom checkboxes
.checkbox-custom {
  padding-left: 0;

  .checkbox-icon {
    background: url("./assets/images/checkbox_unchecked.svg");
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: text-top;

    @media (max-width: 600px) {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked + .checkbox-icon {
      background: url("./assets/images/checkbox_checked.svg");

      @media (max-width: 600px) {
        background-size: 20px;
      }
    }
  }
}

/*
 * Containers
 */
.app-body {
  margin: auto;
  max-width: 1080px;
  padding-bottom: 20px;

  @media (max-width: 600px) {
    // Increased bottom padding so we don't occlude the zendesk help button
    padding-bottom: 40px;
  }

  .no-bottom-padding {
    padding-bottom: 0;
  }
}

.error-page-container {
  margin: 20px 0;
}

.single-column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-column {
  width: 100%;
  max-width: 496px;
}

.column {
  display: flex;
  flex-direction: column;
}

.split-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > * {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    margin: 0 25px 0 15px;
    align-items: center;
  }
}

.action-container {
  display: flex;
  justify-content: center;

  & > *,
  & > .apple-pay-button {
    flex: 1;
    margin-left: 0;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.monthly-option {
  font-size: 1.25rem;
  font-weight: 300;
  color: $blue;
}

.most-popular-callout {
  font-size: 12px;
  color: $blue;
  text-transform: uppercase;
}

.basic-form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.wide {
  width: 100%;
}

.centered {
  text-align: center;
}

.breadcrumbs {
  margin: 20px;
  margin-bottom: 10px;
}

.breadcrumbs > * {
  margin-right: 20px;
}

/*
 * Product List
 */

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-link {
  font-size: 29px;
  margin: 0 auto 20px auto;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
  color: #000000;
}

.product-link:hover {
  color: #000000;
}

.product-link:visited {
  color: #000000;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* https://css-tricks.com/full-width-containers-limited-width-parents/ */
.full-width-breakout {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media (min-width: 600px) {
  .expanded-images {
    margin-top: 150px;
  }
}

.expanded-images img:not(.hidden) {
  display: block; /* Get rid of gap between images */
}

.round-button {
  margin: 3px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid transparent;
  display: inline-block;
}

.round-button--selected {
  border-color: #000000;
}

.round-button--unavailable {
  opacity: 0.4;
  cursor: default;
}

.round-button--contents {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.round-button--color-yellow .round-button--contents {
  background-color: #ffff74;
}

.round-button--color-gray .round-button--contents {
  background-color: #858f97;
}

.round-button--color-blue .round-button--contents {
  background-image: linear-gradient(217deg, #05b5ff 4%, #007dff 97%);
}

.round-button--color-pink .round-button--contents {
  background-image: linear-gradient(180deg, #db3da1 14%, #ff008e 75%);
}

.round-button--color-black .round-button--contents {
  background-color: #363636;
}

.rectangle-button {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  height: 45px;
  padding: 0 0.8125rem;
  margin: 2px 5px 5px;
  cursor: pointer;
  text-align: center;
  line-height: 47px; /* height + top & bottom border */

  &.selected {
    border: 1px solid #000000;
    background-color: #000000;
    color: #ffffff;
  }

  &.blocked {
    color: #d8d8d8;

    del {
      @include strikethrough-del(#d8d8d8);
    }

    &.square-button--selected {
      background-color: red;
      border-color: red;
      color: white;

      del {
        @include strikethrough-del(white);
      }
    }
  }

  &.unavailable {
    cursor: default;
    border: 1px solid transparent;
    color: #d8d8d8;
    text-decoration: line-through;
  }
}

.square-button {
  @extend .rectangle-button;
  padding: 0;
  width: 45px;
}

/*
 * Login Form
 */

.login-form {
  margin-top: 20px;
  text-align: center;

  .login-sub-actions {
    a:not(:first-child) {
      margin-top: 0;
      padding-top: 2px;
    }
  }
}

.login-form h3 {
  text-align: center;
}

.login-body {
  margin: 0 20px;
}

.loading {
  align-self: center;
}
@media (min-width: 600px) {
  .loading {
    max-height: 40vh;
    max-width: 40vw;
  }
}

.loading__image {
  width: 100%;
}

/*
 * Coupon banner
 */
.coupon-banner {
  background-color: #ffff01;
  position: relative;
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }

  .coupon-banner--content {
    text-align: center;
    padding: 3px;
    flex: 1;
  }

  .coupon-banner--gift-box {
    display: inline-block;
    width: 17px;
    height: 16px;
    background: url("/gift_box_black.svg");
    margin-right: 10px;
  }

  .coupon-banner--dismiss-button {
    position: absolute;
    top: 6px;
    right: 30px;
    height: 14px;
    line-height: 14px;
    padding: 3px;
    cursor: pointer;
    display: flex;

    @media (max-width: 600px) {
      position: static;
      align-self: flex-end;
      margin-top: 5px;
      margin-right: 5px;
    }

    .coupon-banner--dismiss-text {
      color: rgba(0, 0, 0, 0.55);
      font-size: 15px;
      line-height: 15px;
      margin-right: 5px;
    }

    .coupon-banner--dismiss-icon {
      width: 14px;
      opacity: 0.3;
      height: 14px;
      display: inline-block;
      background: url("/close_x.svg");
    }
  }
}

/*
 * Display utility styles
 */
.hidden-sm-down {
  display: none;
}
@media (min-width: 768px) {
  .hidden-sm-down {
    display: block;
  }
}

.hidden-sm-up {
  display: inherit;
}
@media (min-width: 768px) {
  .hidden-sm-up {
    display: none;
  }
}

.hidden {
  display: none;
}

.impersonation-warning {
  position: absolute;
  right: 0;
  background-color: #ef5350;
  padding: 3px 6px;
  font-size: 18px;
}

/*
 * Apple Pay
 * See https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons
 */

.apple-pay-button {
  height: 40px;
  margin: 8px 15px 0 15px;
  padding: 0 16px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
  }

  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }

  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
}

//
// Popups
//

.popup-content {
  .popup-body {
    text-align: center;
    padding: 20px 80px;

    @media (max-width: 600px) {
      padding: 20px;
    }
  }

  .popup-actions {
    display: flex;

    > * {
      flex: 1;
      border-top: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      text-align: center;
      padding: 5px;
      cursor: pointer;

      span {
        opacity: 0.5;
      }

      &.popup-primary-action span {
        opacity: 1;
        font-weight: 600;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
