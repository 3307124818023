.rescue-page {
  margin: 50px auto;
  width: 800px;

  .split-container {
    justify-content: space-between;
    margin-top: 3rem;

    > * {
      width: 45%;
      flex: unset;
      margin: 0;
    }

    .rescue-left {
      border-right: 1px solid rgba(0, 0, 0, 0.15);

      p {
        margin-top: 0.5rem;
        max-width: 280px;
        color: rgba(0, 0, 0, 0.75);
      }

      div {
        margin-top: auto;
      }
    }
  }

  h2,
  h5 {
    text-transform: none;
    margin: 0;
  }

  h2 {
    font-weight: 800;
    line-height: 1;
  }

  h5 {
    font-size: 26px;
    line-height: 1;
    font-weight: 300;
  }

  p {
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  .rescue-form {
    width: 100%;
    margin: 0;

    p {
      margin-top: 0;
    }

    > div {
      margin-top: 3rem;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-top: 1rem;
      }
    }

    select {
      min-width: 140px;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0.5rem;
      background-color: #fff;
    }

    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="text"] {
      width: 100%;
      margin-top: 0;
    }

    .button {
      width: 90%;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;

    .rescue-hero {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 300px;
      min-width: 375px;

      img {
        position: absolute;
        top: 0;
        left: -50vw;
      }
    }

    .split-container {
      flex-direction: column;

      > * {
        width: 100%;
        text-align: center;
      }

      h2 {
        margin-bottom: 1rem;
      }

      .rescue-left {
        align-items: center;
        padding-bottom: 2rem;
        margin-bottom: 0;
        border-right: 0px;

        p {
          max-width: 75%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .rescue-right {
        width: 90%;
        margin: 0 auto;
        padding: 3rem 0;
        background: #f9f9f9;
        border-radius: 4px;
      }

      .rescue-form {
        input {
          width: 85%;
        }

        .button {
          width: 70%;
        }
      }
    }
  }
}
