@import "./mixins";
@import "./variables";

// Shared between cart and checkout

.line-item {
  padding: 5px 0;
  margin: 5px 0;

  align-items: center;

  &.gift-card-item {
    align-items: flex-start;
  }

  &.split-container > * {
    align-items: stretch;
  }

  .line-item-body {
    display: flex;
    flex-direction: row;
    margin-left: 0;

    .line-item-details {
      flex-grow: 1;
    }

    .product-pricing {
      flex-grow: 0;
      text-align: right;
    }
  }

  .sku-details {
    @include muted-gray();
    letter-spacing: 0.047rem;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .line-item-gift-card-information {
    margin-top: 1rem;
    font-size: 1rem;

    .gift-line.message {
      margin-top: 0.5rem;
    }
  }

  .product-image {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 0;
  }

  .product-name,
  .line-item-price {
    h4 {
      display: inline-block;
      font-size: 1.8125rem;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .product-name {
    flex-grow: 1;
    h4 {
      font-weight: 600;
    }
  }

  .gift-card-header {
    @include muted-gray();
    height: 1.3rem;
  }

  .line-item-price {
    display: flex;
    align-items: center;
    flex-grow: 0;

    .free-trial-price-qualifier {
      color: $savings-green;
      margin-right: 10px;
    }

    del {
      @include strikethrough-del($savings-green);

      color: rgba(0, 0, 0, 0.33);
      font-weight: 300;
    }

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .free-trial-price-qualifier {
        order: 2;
        margin-right: 0;
        font-size: 15px;
      }
    }
  }

  .remove-button {
    @include muted-gray();
    color: $black-30;

    transition: color 0.15s ease-in-out;

    &:hover {
      color: $black-50;
    }
  }

  &.coupon-line-item {
    .coupon-line-item--description {
      color: $savings-green;

      img {
        margin-right: 5px;
      }
    }
  }
}
