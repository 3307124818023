// Note: I'm using an ID as the selector to get more
// specificity to override some other styles.
#save-account {
  background: white;
  color: black;
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1.5;
  min-height: 420px;

  @media (max-width: 600px) {
    margin-bottom: 60px;
  }

  .save-account-inner {
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .icon-container {
    margin: 0 auto;
    display: inline-block;
    position: relative;

    .squiggle {
      position: absolute;
      left: -47px;
      top: 14px;
    }
  }

  .save-account-complete {
    padding-bottom: 0;
  }

  .password-set-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    img {
      position: relative;
      left: 12px;
      margin-top: 20px;
    }

    .password-set-body {
      flex: 1;
    }
  }
}

/*
 * Thank You overlay
 */

.app-body.thank-you-page-body {
  background-color: #000000;
  background-image: url("../assets/images/thank-you.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 15% 25%;
  margin: 0;
  max-width: none;
}

.overlay {
  z-index: 10;
}

.overlay__content {
  margin: 0 auto;
  max-width: 992px;
  padding: 0 15px;
  overflow: auto;

  .header {
    color: white;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  & > * {
    flex: 1 0 0;
  }
}

.thank-you-page {
  min-height: calc(100vh - 90px);

  &.kiosk .header {
    padding-top: 150px;
  }

  .thank-you-main-container {
    width: 550px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: white;

    @media (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      margin-top: 0;
    }
  }

  h1 {
    font-size: 38px;
    margin-bottom: 1rem;
  }

  .header {
    text-align: center;

    .all-done {
      margin: 20px auto;
    }

    p {
      margin-top: 0;
      margin-bottom: 3rem;
    }

    @media (max-width: 600px) {
      flex: 0;

      p {
        margin-left: 40px;
        margin-right: 40px;
      }
    }
  }

  .thank-you-referrals {
    background-color: white;
    max-width: 820px;
    margin-bottom: 5rem;

    > * {
      margin: 0;
    }

    .thank-you-referrals-left {
      align-items: stretch;
      flex: 0.45;

      > * {
        padding: 0 40px;

        @media (max-width: 600px) {
          padding: 0 15px;
        }
      }

      .thank-you-referrals-details {
        border-bottom: 1px solid #d8d8d8;
        flex: 0.6;
        display: flex;
        flex-direction: column;

        .thank-you-referrals-dog-container {
          display: flex;
          margin: auto 0 20px 0;
        }

        > *:last-child {
          margin-bottom: 40px;
        }
      }

      .thank-you-referrals-code-container {
        flex: 0.4;

        .thank-you-referrals-code-header {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1rem;
          letter-spacing: 0.075rem;
          color: rgba(0, 0, 0, 0.72);
          margin-bottom: 10px;
        }

        > *:first-child,
        > *:last-child {
          margin-top: 40px;
        }
      }
    }

    .thank-you-referrals-right {
      flex: 0.55;
      background-color: $light-gray;
      border-left: 1px solid #d8d8d8;
    }

    .thank-you-tiers {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      margin-bottom: 20px;

      .referral-reward-unlocks-progress {
        display: none;
      }

      .referral-reward-separator {
        background: none;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      width: 90%;

      .thank-you-referrals-left {
        text-align: center;

        .referral-code-container {
          text-align: left;
        }

        .thank-you-referrals-dog-container {
          align-self: center;
        }

        &:last-child {
          margin-bottom: 20px;
        }
      }

      .thank-you-referrals-left .thank-you-referrals-details,
      .thank-you-referrals-code-container {
        > *:last-child {
          margin-bottom: 20px;
        }

        &.thank-you-referrals-code-container > *:last-child {
          margin-top: 20px;
        }
      }

      h4 {
        margin-top: 20px;
        text-align: center;
      }

      .thank-you-tiers .referral-reward {
        font-size: 0.65em;
        height: 75px;

        &.one-year,
        &.two-years,
        &.three-years {
          background-size: 40%;
          background-position: right 5px center;
        }
      }
    }
  }
}
