$savings-green: #2cb355;
$blue: #3497ff;
$red: #ff004f;
$yellow: #fffa02;

$light-gray: #f5f5f5;

$black-70: rgba(
  $color: #000000,
  $alpha: 0.7
);

$black-50: rgba(
  $color: #000000,
  $alpha: 0.5
);

$black-30: rgba(
  $color: #000000,
  $alpha: 0.3
);

$black-20: rgba(
  $color: #000000,
  $alpha: 0.2
);

$black-10: rgba(
  $color: #000000,
  $alpha: 0.1
);
