@import "./variables";

@keyframes text-fade {
  0% {
    color: rgba(204, 204, 204, 0);
  }
  100% {
    color: rgba(204, 204, 204, 1);
  }
}

@keyframes shake-bag-grow-anim {
  0% {
    transform: scale(1);
  }
  98% {
    transform: scale(1.22) rotate(0turn);
  }
  100% {
    transform: scale(1.2) rotate(0.01turn);
  }
}

@keyframes shake-bag-shrink-anim {
  0% {
    transform: scale(1.2) rotate(0.01turn);
  }
  30% {
    transform: scale(1.3) rotate(-0.01turn);
  }
  100% {
    transform: scale(1);
  }
}

.app-bar {
  color: #ffffff;
  box-sizing: border-box;
  background-color: #000000;
  width: 100%;
  height: 78px;
  max-width: 100vw;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: normal;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .drop_arrow {
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
    opacity: 0.5;
    display: inline;
    transition: all ease-in-out 0.15s;
  }

  .logo {
    height: 36px;
    margin-top: 0.4rem;
    width: 40px;
    z-index: 99;
    position: relative;
  }

  .dropdown_trigger {
    &.hover {
      .drop_arrow {
        transform: rotate(180deg);
        margin-top: -3px;
      }

      .dropdown {
        height: 105px;

        hr {
          background: rgba(255, 255, 255, 0.2);
        }

        .dropdown_inner {
          top: 0;
          background: rgba(0, 0, 0, 1);
          opacity: 1;

          .nav__link {
            animation: text-fade 0.9s ease-in-out;
            animation-iteration-count: 1;

            img {
              margin-top: 0;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .dropdown {
      background: transparent;
      position: absolute;
      width: 100vw;
      left: -70px;
      top: 41px;
      margin: 0;
      padding: 0;
      height: 0px;
      overflow: hidden;
      transition: all linear 0.25s;

      &.sto {
        .dropdown_inner {
          padding-left: 190px;
        }
      }

      hr {
        width: 100%;
        height: 1px;
        position: absolute;
        top: 20px;
        background: rgba(255, 255, 255, 0);
        border: none;
        padding: 0;
        margin: 0;
        transition: all linear 0.5s;
      }

      .dropdown_inner {
        display: flex;
        flex: 0 0 100%;
        background: rgba(0, 0, 0, 0);
        opacity: 0;
        top: -100%;
        height: 100%;
        padding-left: 72px;
        align-items: center;
        justify-content: flex-start;
        transition: all ease-in-out 0.35s;

        .nav__link {
          font-size: 16px;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 30%;
          max-width: 150px;
          margin: 20px 10px 0 10px;
          padding: 0;
          transition: all 0.25s ease-in-out;

          .dp_icon {
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 8px;
            transition: all 0.25s ease-in-out;
          }

          img {
            display: block;
            margin: -40px auto 0px auto;
            opacity: 0;
            max-height: 30px;
            opacity: 0.5;
            transition: all 0.5s ease-in-out;
          }

          &:hover {
            .dp_icon {
              margin-bottom: 2px;
            }

            img {
              opacity: 1;
            }
          }
        }
      }
    }
  } /* DROP DOWN TRIGGER AND DROP DOWN END */
} /* APP BAR */

.nav {
  /* Absolute positioning for mobile */
  position: absolute;
  top: 78px;
  left: 0px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  width: 100%;
  z-index: 100;
}

@media (min-width: 768px) {
  .nav {
    position: relative;
    top: auto;
    left: auto;
    flex-direction: row;
  }
}

.hidden-nav {
  display: none;
}
@media (min-width: 768px) {
  .hidden-nav {
    display: flex;
  }

  .nav__link--session {
    margin-left: auto;
  }
}

.nav__link {
  color: #ccc;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 0.35rem;
  margin-right: 1rem;
  letter-spacing: 0.025rem;
  transition: color ease-in-out 0.15s;

  &:hover {
    color: #fff;
  }
}

.nav__link--active {
  color: #fff;
  letter-spacing: 0.05rem;
}

@media (min-width: 768px) {
  #main-nav {
    padding-left: 30px;

    .right-nav {
      margin-left: auto;
    }
  }
}

#main-nav {
  z-index: 100; // Have to make it below the shopping bag for bag animation
}

.shopping-bag {
  display: flex;
  align-items: center;
  color: #ccc;
  font-size: 18px;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  text-decoration: none;
  overflow: visible;
  padding: 0;
  margin: 0;
  z-index: 101; // Have to make it above the main-nav for bag animation
}

.shopping-bag__icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  overflow: visible;

  &.growing {
    animation: shake-bag-grow-anim 0.3s ease-in 1 forwards;
  }

  &.shrinking {
    animation: shake-bag-shrink-anim 0.35s ease-in-out 1;
  }
}

@media (max-width: 767px) {
  .drop_arrow.hidden-sm-down {
    display: none;
  }
  .dropdown_trigger {
    font-size: 18px;
    padding: 0.5rem 0;
    text-indent: 30px;
    .dropdown {
      position: relative;
    }
  }
  .nav {
    padding: 0 0 1.5rem;
    align-items: flex-start;
    overflow-x: hidden;
    hr {
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin: 1rem 0;
      padding: 0;
      width: 100%;
      border: none;
    }
    .nav__link {
      font-size: 18px;
      padding: 0.5rem 0;
      width: 100%;
      text-indent: 30px;
      font-weight: 600;

      &.hidden-sm-up {
        text-indent: 40px;
        font-weight: 400;
      }
    }
    .right-nav {
      display: flex;
      flex: 0 0 100%;
      margin-left: 0;
      flex-direction: column;

      .nav__link.hidden-sm-up {
        text-indent: 30px;
        font-weight: 600;
      }
    }
  }
  .navbar-toggler-icon {
    background: no-repeat center center;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    width: 38px;
    height: 38px;
  }
}
